import React from "react"
import Layout from "../components/Layout/layout"
import Markdown from 'markdown-to-jsx';
import SEO from "../components/Utils/seo"
import { render } from 'react-dom';

export default function About(props) {
	return (
		<Layout>
			{/* siteTitle specifies that the title will the siteTitle alone */}
			<SEO title="Bosco's Library" />
			<section>
				<h1>My Library</h1>
				<h4>A list of my favourite books.</h4>

				<Markdown>
					The books I read, have read and must read before death.
					I believe knowledge is freedom, freedom to do more, be more, to create and give more. The reason you are where you are today is because you know what you know and don't know what you don't know. Imagine if you knew more, you could take higher levels of action, do more with your time on earth and impact the world in ways your old knowing self could only dream about, I found myself in books, I know you might too.  
				</Markdown>
				<br></br>
				<br></br>
				<Markdown>
					“Don't join the book burners. Don't think you're going to conceal faults by concealing evidence that they ever existed. Don't be afraid to go in your library and read every book...” 
					― Dwight D. Eisenhower
				</Markdown>
				<br></br>
				<br></br>
				<Markdown>
					![alt tag](https://media1.tenor.com/images/53a44ea8d17e8e8a8c1d200cd2a2c466/tenor.gif?itemid=4432507)
				</Markdown>
				<br></br>
				<br></br>
				<Markdown>
					- [Una Vision Desde La Cumbre](https://www.amazon.ca/Vision-Desde-Cumbre-Edmund-Hillary/dp/8483062119)
				</Markdown>
				<Markdown>

					- [Climbing Ice](https://www.amazon.com/Climbing-Ice-Yvon-Chouinard/dp/0871562073)
</Markdown>

<Markdown>

					- [The Art of Learning](https://www.amazon.ca/Art-Learning-Journey-Optimal-Performance/dp/0743277465)
</Markdown>
<Markdown>

					- [Principles](https://www.goodreads.com/book/show/12935037-principles)
</Markdown>
<Markdown>
					- [My Inventions: The Autobiography of Nikola Tesla](https://www.amazon.com/My-Inventions-Autobiography-Nikola-Tesla/dp/161293093X/ref=sr_1_1?s=books&ie=UTF8&qid=1500856859&sr=1-1&keywords=nikola+tesla+biography)
</Markdown>
<Markdown>
					- [The Black Jacobins](https://www.amazon.com/dp/0679724672/?tag=mh0b-20&hvadid=77653016423262&hvqmt=e&hvbmt=be&hvdev=c&ref=pd_sl_5e878pvw0q_e)
</Markdown>
<Markdown>
					- [We Learn Nothing](https://www.amazon.com/We-Learn-Nothing-Tim-Kreider/dp/1439198713)
</Markdown>
<Markdown>
					- [Wildest Dream: The Biography of George Mallory](https://www.amazon.com/Wildest-Dream-Biography-George-Mallory/dp/0898867517)
</Markdown>
<Markdown>
					- [Lincoln on Leadership](https://www.amazon.com/Lincoln-Leadership-Executive-Strategies-Tough/dp/0446394599/ref=sr_1_1?s=books&ie=UTF8&qid=1531633127&sr=1-1&keywords=Lincoln+on+Leadership)
</Markdown>
<Markdown>
					- [Feynman Lectures On Gravitation](https://www.amazon.com/dp/0813340381)
</Markdown>
<Markdown>
					- [Letters From A Stoic](https://www.goodreads.com/book/show/97411.Letters_from_a_Stoic)
</Markdown>
<Markdown>
					- [She Comes First: The Thinking Man's Guide to Pleasuring a Woman](https://www.amazon.com/She-Comes-First-Thinking-Pleasuring/dp/0060538260/ref=pd_lpo_sbs_14_t_1?_encoding=UTF8&psc=1&refRID=1N0D01H803CP4TDY6BW8)
</Markdown>
<Markdown>
					- [Self-Reliance](https://www.goodreads.com/book/show/1760630.Self_Reliance)
</Markdown>
<Markdown>
					- [The Nazi Officer's Wife](https://www.amazon.com/Nazi-Officers-Wife-Survived-Holocaust/dp/068817776X)
</Markdown>
<Markdown>
					- [Makeba: My Story](https://www.amazon.com/Makeba-My-Story-Miriam/dp/0452262348/ref=la_B001HP2VCM_1_1/140-3813511-2117713?s=books&ie=UTF8&qid=1516767147&sr=1-1)
</Markdown>
<Markdown>
					- [It's Your Ship: Management Techniques from the Best Damn Ship in the Navy](https://www.amazon.com/Its-Your-Ship-Management-Anniversary/dp/145552302X)
</Markdown>
<Markdown>
					- [The Little Flowers of St. Francis](https://www.amazon.com/Little-Flowers-St-Francis-Additional/dp/0385075448)
</Markdown>
<Markdown>
					- [The Emperor's Handbook](https://www.goodreads.com/book/show/20937210-the-emperor-s-handbook)
</Markdown>
<Markdown>
					- [The Measure of a Man: A Spiritual Autobiography](https://www.amazon.com/Measure-Man-Spiritual-Autobiography-Oprahs/dp/0061357901/ref=sr_1_2?s=books&ie=UTF8&qid=1513296159&sr=1-2&keywords=measure+of+a+man)
</Markdown>
<Markdown>
					- [The Hero with a Thousand Faces](https://www.amazon.com/Thousand-Faces-Collected-Joseph-Campbell/dp/1577315936/ref=asap_bc?ie=UTF8)
</Markdown>
<Markdown>
					- [A Class with Drucker: The Lost Lessons of the World's Greatest Management Teacher](https://www.amazon.ca/Class-Drucker-Lessons-Greatest-Management/dp/0814414184)
</Markdown>
<Markdown>
					- [The Business of Changing the World](https://www.amazon.com/Business-Changing-World-Strategic-Philanthropy/dp/0071481516)
</Markdown>
<Markdown>
					- [The Great Leveler](https://www.amazon.com/Great-Leveler-Inequality-Twenty-First-Princeton/dp/0691165025)
</Markdown>
<Markdown>
					- [The Outsiders](https://www.amazon.com/Outsiders-Unconventional-Radically-Rational-Blueprint-ebook/dp/B009G1T74O/ref=pd_sim_351_4?_encoding=UTF8&psc=1&refRID=32JG4J43GE14F09GAYNA)
</Markdown>
<Markdown>
					- [The One Device](https://www.amazon.com/One-Device-Secret-History-iPhone/dp/031654616X)
</Markdown>
<Markdown>
					- [Hit Refresh](https://www.amazon.com/Hit-Refresh-Rediscover-Microsofts-Everyone-ebook/dp/B01HOT5SQA)
</Markdown>
<Markdown>
					- [Kama Sutra For Beginners](https://www.amazon.com/Kama-Sutra-Beginners-Essential-Techniques/dp/1515297888/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=EB1CYZB0S6Q9N7KES358)
</Markdown>
<Markdown>
					- [The Over-Soul](https://www.amazon.com/Over-Soul-According-Emerson/dp/1425468942)
</Markdown>
<Markdown>
					- [Lost Victories](https://www.amazon.com/Lost-Victories-Memoirs-Hitlers-Brilliant/dp/0760320543/ref=pd_cp_14_1?_encoding=UTF8&pd_rd_i=0760320543&pd_rd_r=2GTZQ7AGEPR94S818KHR&pd_rd_w=n2QfE&pd_rd_wg=8YV4L&psc=1&refRID=2GTZQ7AGEPR94S818KHR)
</Markdown>
<Markdown>
					- [Creativity, Inc.: Overcoming the Unseen Forces That Stand in the Way of True Inspiration](https://www.amazon.com/Creativity-Inc-Overcoming-Unseen-Inspiration/dp/0812993012)
</Markdown>
<Markdown>
					- [How Google Works](http://www.howgoogleworks.net/)
</Markdown>
<Markdown>
					- [Nothing Ventured, Nothing Win](https://www.amazon.com/gp/product/0340486139/ref=dbs_a_def_rwt_bibl_vppi_i5)
</Markdown>
<Markdown>
					- [High Adventure](https://www.amazon.com/gp/product/1932302026/ref=dbs_a_def_rwt_hsch_vapi_taft_p1_i0)
</Markdown>
<Markdown>
					- [On Liberty](https://www.amazon.com/Liberty-John-Stuart-Mill-ebook/dp/B076X6Q2RZ/ref=asap_bc?ie=UTF8)
</Markdown>
<Markdown>
					- [Autobiography](https://www.amazon.com/Autobiography-Penguin-Classics-John-Stuart/dp/0140433163/ref=la_B000APZ4H4_1_4?s=books&ie=UTF8&qid=1510626652&sr=1-4)
</Markdown>
<Markdown>
					- [How Not to Be Wrong: The Power of Mathematical Thinking](https://www.amazon.com/How-Not-Be-Wrong-Mathematical/dp/0143127535)
</Markdown>
<Markdown>
					- [The Diary of a Young Gir](https://www.goodreads.com/book/show/48855.The_Diary_of_a_Young_Girl)
</Markdown>
<Markdown>
					- [What Do You Care What Other People Think?](https://www.amazon.com/What-Care-Other-People-Think/dp/0393320928)
</Markdown>
<Markdown>
					- [Reinventing Gravity](http://www.goodreads.com/book/show/3050585-reinventing-gravity)
</Markdown>
<Markdown>
					- [Be Here Now](https://drive.google.com/drive/folders/0B3o2ZoX248cWVEd1SGxHSDVvaXM)
</Markdown>
<Markdown>
					- [Zero to One](http://www.amazon.ca/Zero-One-Notes-Startups-Future/dp/0804139296/ref=sr_1_1?s=books&ie=UTF8&qid=1450596054&sr=1-1&keywords=zero+to+one)
</Markdown>
<Markdown>
					- [Antifragile: Things That Gain from Disorder](https://www.goodreads.com/book/show/13530973-antifragile)
</Markdown>
<Markdown>
					- [The Mind Of The Strategist: The Art of Japanese Business](https://www.amazon.com/Mind-Strategist-Art-Japanese-Business/dp/0070479046/ref=sr_1_1?ie=UTF8&s=books&qid=1279071075&sr=8-1)
</Markdown>
<Markdown>
					- [Today and Tomorrow](https://www.amazon.com/Today-Tomorrow-Special-Fords-Classic/dp/0915299364)
</Markdown>
<Markdown>
					- [Edison As I Know Him](https://www.amazon.com/Edison-As-I-Know-Him/dp/1432561588)
</Markdown>
<Markdown>
					- [Healing Back Pain: The Mind-Body Connection](https://www.amazon.com/Healing-Back-Pain-Mind-Body-Connection/dp/0446557684)
</Markdown>
<Markdown>
					- [Maxims for Revolutionists](https://www.amazon.com/Maxims-Revolutionists-Bernard-Shaw/dp/1499591756)
</Markdown>
<Markdown>
					- [How The Mighty Fall: And Why Some Companies Never Give In](https://www.amazon.com/How-Mighty-Fall-Companies-Never/dp/0977326411)
</Markdown>
<Markdown>
					- [The Diary of a Young Girl](https://www.amazon.ca/Diary-Young-Girl-Anne-Frank/dp/0553296981/ref=asc_df_0553296981/?tag=googleshopc0c-20&linkCode=df0&hvadid=263678563891&hvpos=1o2&hvnetw=g&hvrand=5386102841406068724&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9001454&hvtargid=pla-433800418016&psc=1)
</Markdown>
<Markdown>
					- [Augustine's Laws, Sixth Edition](https://www.amazon.com/Augustines-Chairman-Lockheed-Corporation-Augustine/dp/1563472406)
</Markdown>
<Markdown>
					- [The Way to Wealth](https://www.youtube.com/watch?v=kV6ju75wqxI)
</Markdown>
<Markdown>
					- [The Innovator's Dilemma](https://www.amazon.com/Innovators-Dilemma-Revolutionary-Change-Business/dp/0062060244)
</Markdown>
<Markdown>
					- [The Alchemist](https://www.amazon.com/Alchemist-Paulo-Coelho/dp/0062315005/ref=dp_ob_title_bk)
</Markdown>
<Markdown>
					- [Tribal Leadership](https://www.amazon.com/Tribal-Leadership-Leveraging-Thriving-Organization/dp/0061251321)
</Markdown>
<Markdown>
					- [The Art of Peace](https://www.amazon.com/Art-Peace-Morihei-Ueshiba/dp/1590304489)
</Markdown>
<Markdown>
					- [Winning](https://www.amazon.com/Winning-Ultimate-Business-How-Book-ebook/dp/B000FCK3GO)
</Markdown>
<Markdown>
					- [Jack](https://www.amazon.com/Jack-Straight-Gut-Welch-ebook/dp/B000FBJDD4)
</Markdown>
<Markdown>
					- [Stress Test](https://www.amazon.com/Stress-Test-Reflections-Financial-Crises-ebook/dp/B00IN73B9E/ref=pd_sim_351_4?_encoding=UTF8&psc=1&refRID=TVFHTCPS4CDJ1RDC7CW9)
</Markdown>
<Markdown>
					- [Compassionate Capitalism](https://www.amazon.com/Compassionate-Capitalism-Corporations-Doing-Integral/dp/1564147142)
</Markdown>
<Markdown>
					- [The Pyramid Principle: Logic in Writing and Thinking](https://www.amazon.com/Pyramid-Principle-Logic-Writing-Thinking/dp/0273710516)
</Markdown>
<Markdown>
					- [The Personal MBA: Master the Art of Business](https://www.amazon.com/gp/product/1591843529/)
</Markdown>
<Markdown>
					- [George Washington: A Life From Beginning to End](https://www.amazon.ca/George-Washington-Beginning-President-Biographies-ebook/dp/B01KTB6IJ6/ref=sr_1_11?s=books&ie=UTF8&qid=1537119305&sr=1-11&keywords=george+washington)
</Markdown>
<Markdown>
					- [The Outsiders](https://www.amazon.com/Outsiders-Unconventional-Radically-Rational-Blueprint-ebook/dp/B009G1T74O/ref=pd_sim_351_3?_encoding=UTF8&psc=1&refRID=TVFHTCPS4CDJ1RDC7CW9)
</Markdown>
<Markdown>
					- [The Inner Citadel](https://www.amazon.com/Inner-Citadel-Meditations-Marcus-Aurelius/dp/0674007077)
</Markdown>
<Markdown>
					- [Dying Every Day](https://www.amazon.com/Dying-Every-Day-Seneca-Court/dp/0307596877)
</Markdown>
<Markdown>
					- [Rome’s Last Citizen](https://www.goodreads.com/book/show/13538752-rome-s-last-citizen)
</Markdown>
<Markdown>
					- [A Man in Full](https://www.amazon.com/Man-Full-Tom-Wolfe/dp/0553381334)
</Markdown>
<Markdown>
					- [The Philosophy of Cognitive Behavioural Therapy](https://www.goodreads.com/book/show/10140570-the-philosophy-of-cognitive-behavioural-therapy)
</Markdown>
<Markdown>
					- [An American Sickness: How Healthcare Became Big Business and How You Can Take It Back](https://www.amazon.com/American-Sickness-Healthcare-Became-Business/dp/1594206759)
</Markdown>
<Markdown>
					- [Stoic Warriors: The Ancient Philosophy behind the Military Mind](https://www.amazon.com/Stoic-Warriors-Ancient-Philosophy-Military/dp/019531591X)
</Markdown>
<Markdown>
					- [Leading](https://www.amazon.com/Leading-Learning-Years-Manchester-United/dp/0316268100)
</Markdown>
<Markdown>
					- [Hitchhiker's Guide to the Galaxy](https://www.amazon.com/Universe-Everything-Hitchhikers-Guide-Galaxy/dp/0345391829)
</Markdown>
<Markdown>
					- [Confessions of St.Augustine](http://www.goodreads.com/book/show/27037.Confessions)
</Markdown>
<Markdown>
					- [The Handbook of Mathematical Functions](http://people.math.sfu.ca/~cbm/aands/abramowitz_and_stegun.pdf)
</Markdown>
<Markdown>
					- [Stories of Don Bosco](http://www.amazon.com/Stories-of-Don-Bosco/dp/B000UGBKF8)
</Markdown>
<Markdown>
					- [What I Wish I Knew When I Was 20](http://www.amazon.ca/What-Wish-Knew-When-Was/dp/0061735191)
</Markdown>
<Markdown>
					- [Calculus: Early Transcendentals, 7E](http://www.amazon.com/Calculus-Early-Transcendentals-Alternate-Edition/dp/0840058454)
</Markdown>
<Markdown>
					- [Star Wars: Heir to the Empire](http://www.goodreads.com/book/show/216443.Heir_to_the_Empire)
</Markdown>
<Markdown>
					- [Star Wars: Dark Force Rising](http://www.goodreads.com/book/show/216442.Dark_Force_Rising?from_search=true&search_version=service)
</Markdown>
<Markdown>
					- [On the Shortness of Life](https://www.amazon.com/Shortness-Life-Penguin-Great-Ideas/dp/0143036327)
</Markdown>
<Markdown>
					- [Star Wars: The Last Command](http://www.goodreads.com/book/show/216422.The_Last_Command?from_search=true&search_version=service)
</Markdown>
<Markdown>
					- [From Third World to First: The Singapore Story: 1965-2000](https://www.goodreads.com/book/show/144409.From_Third_World_to_First)
</Markdown>
<Markdown>
					- [The Remains of the Day](https://www.goodreads.com/book/show/28921.The_Remains_of_the_Day)
</Markdown>
<Markdown>
					- [The Story of My Experiments With Truth](https://www.goodreads.com/book/show/112803.The_Story_of_My_Experiments_With_Truth)
</Markdown>
<Markdown>
					- [Erich Von Manstein](https://www.amazon.com/Erich-Von-Manstein-Hitlers-Strategist/dp/1612000592)
</Markdown>
<Markdown>
					- [Crucial Conversations Tools for Talking When Stakes Are High, Second Edition](http://www.amazon.ca/Crucial-Conversations-Talking-Stakes-Edition/dp/0071771328)
</Markdown>
<Markdown>
					- [Drive: The Surprising Truth About What Motivates Us](http://www.amazon.ca/Drive-Surprising-Truth-About-Motivates/dp/1594484805)
</Markdown>
<Markdown>
					- [The Dip: A Little Book That Teaches You When to Quit (and When to Stick)](https://www.amazon.com/Dip-Little-Book-Teaches-Stick/dp/1591841666)
</Markdown>

<Markdown>
					- [The Minto Pyramid Principle: Logic in Writing, Thinking, & Problem Solving](https://www.amazon.com/Pyramid-Principle-Writing-Thinking-Problem/dp/0960191046/ref=sr_1_1?ie=UTF8&s=books&qid=1279071203&sr=8-1)
</Markdown>
<Markdown>
					- [Bird by Bird](https://www.amazon.com/Bird-Some-Instructions-Writing-Life/dp/0385480016/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=418W10Z0WPY99PMGJZJ0)
</Markdown>
<Markdown>
					- [Ernest Hemingway on Writing](https://www.amazon.com/Ernest-Hemingway-Writing-Larry-Phillips/dp/0684854295)
</Markdown>
<Markdown>
					- [The Book of Five Rings](https://www.amazon.com/Book-Five-Rings-Miyamoto-Musashi/dp/1935785974/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=2FDCXANQVZRDPR77EDPR)
</Markdown>
<Markdown>
					- [Zen Mind, Beginner's Mind](https://www.amazon.com/gp/product/1590302672?ie=UTF8&tag=offsitoftimfe-20&linkCode=as2&camp=1789&creative=390957&creativeASIN=1590302672)
</Markdown>
<Markdown>
					- [The Mythical Man-Month](https://www.amazon.com/Mythical-Man-Month-Software-Engineering-Anniversary/dp/0201835959)
</Markdown>
<Markdown>
					- [Selling the Invisible](https://www.amazon.com/Selling-Invisible-Field-Modern-Marketing/dp/0446672319)
</Markdown>
<Markdown>
					- [The Intelligent Investor](https://www.amazon.com/gp/product/0060555661?ie=UTF8&tag=offsitoftimfe-20&linkCode=as2&camp=1789&creative=390957&creativeASIN=0060555661)
</Markdown>
<Markdown>
					- [The 4 Disciplines of Execution](https://www.amazon.com/Disciplines-Execution-Achieving-Wildly-Important/dp/1491517751)
</Markdown>
<Markdown>
					- [Asking the Right Questions](https://www.amazon.com/Asking-Right-Questions-11th-Browne/dp/0321907957/)
</Markdown>
<Markdown>
					- [8 Weeks to Optimum Health](https://www.amazon.com/gp/product/034549802X?ie=UTF8&tag=offsitoftimfe-20&linkCode=as2&camp=1789&creative=390957&creativeASIN=034549802X)
</Markdown>
<Markdown>
					- [Judgment in Managerial Decision Making](https://www.amazon.com/gp/product/1118065700/ref=as_at/?imprToken=7TH22-9F-6X3yYXvHXe.JA&slotNum=0&ie=UTF8&tag=farnamstreet-20&camp=1789&creative=9325&linkCode=w61&creativeASIN=1118065700&linkId=090ca2216bc46c9489a0ae362e14f1bd)
</Markdown>
<Markdown>
					- [Measure What Matters](https://www.amazon.com/Measure-What-Matters-Google-Foundation/dp/0525536221)
</Markdown>
<Markdown>
					- [Scrum: The Art of Doing Twice the Work in Half the Time](https://www.amazon.com/Scrum-Doing-Twice-Work-Half/dp/038534645X)
</Markdown>
<Markdown>
					- [Agile Software Development, Principles, Patterns, and Practices](https://www.goodreads.com/book/show/84985.Agile_Software_Development_Principles_Patterns_and_Practices)
</Markdown>
<Markdown>
					- [Managing Humans](https://www.amazon.com/Managing-Humans-Humorous-Software-Engineering/dp/1484221575/ref=as_li_ss_tl?ie=UTF8&qid=1486923077&sr=8-1&keywords=managing+humans&linkCode=sl1&tag=beigee-20&linkId=2218253ddaf38dfc4ae03043cb7da490)
</Markdown>
<Markdown>
					- [The Tipping Point](https://www.amazon.com/gp/product/0316346624?ie=UTF8&tag=offsitoftimfe-20&linkCode=as2&camp=1789&creative=390957&creativeASIN=0316346624)
</Markdown>
<Markdown>
					- [The 22 Immutable Laws of Marketing](https://www.amazon.com/gp/product/0887306667?ie=UTF8&tag=offsitoftimfe-20&linkCode=as2&camp=1789&creative=390957&creativeASIN=0887306667)
</Markdown>
<Markdown>
					- [Achtung - Panzer!](https://www.amazon.com/Achtung-Panzer-Cassell-Military-Classics/dp/0304352853/ref=pd_bxgy_14_img_3?_encoding=UTF8&pd_rd_i=0304352853&pd_rd_r=32b6374d-6494-11e8-8ce9-a3e8d727e07b&pd_rd_w=0IyUi&pd_rd_wg=lgG4L&pf_rd_i=desktop-dp-sims&pf_rd_m=ATVPDKIKX0DER&pf_rd_p=3914568618330124508&pf_rd_r=06M4HXH87MY4QZKC49JH&pf_rd_s=desktop-dp-sims&pf_rd_t=40701&psc=1&refRID=06M4HXH87MY4QZKC49JH)
</Markdown>
<Markdown>
					- [Envisioning Information](https://www.amazon.com/gp/product/0961392118?ie=UTF8&tag=offsitoftimfe-20&linkCode=as2&camp=1789&creative=390957&creativeASIN=0961392118)
</Markdown>
<Markdown>
					- [Leaving Microsoft to Change the World](https://www.amazon.com/gp/product/0061121088?ie=UTF8&tag=offsitoftimfe-20&linkCode=as2&camp=1789&creative=390957&creativeASIN=0061121088)
</Markdown>
<Markdown>
					- [The Goal](https://www.amazon.com/Goal-Process-Ongoing-Improvement/dp/0884271951)
</Markdown>
<Markdown>
					- [Enchiridion](https://www.amazon.com/Enchiridion-Dover-Thrift-Editions-Epictetus/dp/0486433595)
</Markdown>
<Markdown>
					- [Fortune's Formula](https://www.amazon.com/Fortunes-Formula-Scientific-Betting-Casinos/dp/0809045990)
</Markdown>
<Markdown>
					- [Getting Real](http://gettingreal.37signals.com/)
</Markdown>
<Markdown>
					- [Conversational Intelligence](https://www.amazon.com/Conversational-Intelligence-Leaders-Extraordinary-Results/dp/1629561436/ref=pd_lpo_sbs_14_t_1?_encoding=UTF8&psc=1&refRID=ABXWWD50BH6TSMRSQDSW)
</Markdown>
<Markdown>
					- [Humble Inquiry](https://www.amazon.com/Humble-Inquiry-Gentle-Instead-Telling/dp/1609949811/ref=pd_lpo_sbs_14_t_0?_encoding=UTF8&psc=1&refRID=ABXWWD50BH6TSMRSQDSW&dpID=51sIUylNuzL&preST=_SY291_BO1,204,203,200_QL40_&dpSrc=detail)
</Markdown>
<Markdown>
					- [A More Beautiful Question](https://www.amazon.com/More-Beautiful-Question-Inquiry-Breakthrough/dp/1632861054/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=ABXWWD50BH6TSMRSQDSW)
</Markdown>
<Markdown>
					- [The Power Broker](https://www.amazon.com/gp/product/0394720245/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Out of Control](https://www.amazon.com/Out-Control-Biology-Machines-Economic/dp/0201483408)
</Markdown>
<Markdown>
					- [Personal History](https://www.amazon.com/gp/product/B004FYZ3P4/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Steal Like an Artist](https://www.amazon.com/gp/product/B0074QGGK6/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Status Anxiety](https://www.amazon.com/gp/product/B001NJUPD4/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [What I Learned Losing A Million Dollars](https://www.amazon.com/gp/product/B00MNMHBA0/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Cyropaedia](https://www.amazon.com/gp/product/1456498835/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Xenophon's Cyrus the Great](https://www.amazon.com/gp/product/0312364695/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Lord Chesterfield's Letters](https://www.amazon.com/gp/product/0199554846/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Plutarch's Lives Volume 1](https://www.amazon.com/gp/product/0375756760/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [The Lives of the Most Excellent Painters, Sculptors, and Architects](https://www.amazon.com/gp/product/0375760369?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [7 Powers: The Foundations of Business Strategy](https://www.amazon.com/7-Powers-Foundations-Business-Strategy-ebook/dp/B01MRLFFQ7)
</Markdown>
<Markdown>
					- [The Manager's Path: A Guide for Tech Leaders Navigating Growth and Change](https://www.amazon.com/Managers-Path-Leaders-Navigating-Growth/dp/1491973897)
</Markdown>
<Markdown>
					- [Cracking the Sales Management Code](https://www.amazon.com/Cracking-Sales-Management-Code-Performance/dp/0071765735/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=EAMTYSNRK1SHFZ2VV7EQ)
</Markdown>
<Markdown>
					- [Up the Organization](https://www.amazon.com/Up-Organization-Corporation-Stifling-Strangling/dp/0787987751)
</Markdown>
<Markdown>
					- [Predictable Revenue](https://www.amazon.com/Predictable-Revenue-Business-Practices-Salesforce-com/dp/0984380213/ref=pd_lpo_sbs_14_t_1?_encoding=UTF8&psc=1&refRID=EAMTYSNRK1SHFZ2VV7EQ)
</Markdown>
<Markdown>
					- [The Best Book on DeepLearning](http://www.deeplearningbook.org/)
</Markdown>
<Markdown>
					- [The Book of Five Rings](https://www.amazon.com/gp/product/1590309847/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [The Kid Stays in the Picture](https://www.amazon.com/gp/product/0062228323/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Lafayette](https://www.amazon.com/Lafayette-Harlow-Giles-Unger/dp/0471468851)
</Markdown>
<Markdown>
					- [Memoirs Of General Lafayette](https://www.goodreads.com/book/show/6349785-memoirs-of-general-lafayette)
</Markdown>
<Markdown>
					- [Empire State of Mind](https://www.amazon.com/gp/product/1591845408/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Self-theories](https://www.amazon.com/Self-theories-Motivation-Personality-Development-Psychology/dp/1841690244)
</Markdown>
<Markdown>
					- [The Fish That Ate the Whale](https://www.amazon.com/gp/product/1250033314/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [The Accidental Superpower](https://www.amazon.com/Accidental-Superpower-Generation-American-Preeminence/dp/1455583685)
</Markdown>
<Markdown>
					- [The Autobiography of Malcolm X](https://www.amazon.com/gp/product/0345350685/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [The Millennium Whole Earth Catalog](https://www.amazon.com/Millennium-Whole-Earth-Catalog/dp/0062510592)
</Markdown>
<Markdown>
					- [Competing on the Edge : Strategy as Structured Chaos](https://www.amazon.com/Competing-Edge-Strategy-Structured-Chaos/dp/0875847544)
</Markdown>
<Markdown>
					- [Confessions of the Pricing Man](https://www.amazon.com/gp/product/B016XMVQA6/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&tag=jamesclear-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B016XMVQA6&linkId=2fde7cd70e79645749b49c78a7e0f81d)
</Markdown>
<Markdown>
					- [The Hero with a Thousand Faces](https://www.amazon.com/Thousand-Faces-Collected-Joseph-Campbell/dp/1577315936)
</Markdown>
<Markdown>
					- [The Lessons of History](https://www.amazon.com/Lessons-History-Will-Durant/dp/143914995X)
</Markdown>
<Markdown>
					- [The Gift of Fear](https://www.goodreads.com/book/show/56465.The_Gift_of_Fear)
</Markdown>
<Markdown>
					- [Abundance: The Future Is Better Than You Think](https://www.amazon.com/Abundance-Future-Better-Than-Think/dp/1451614217)
</Markdown>
<Markdown>
					- [Steve Jobs](https://www.amazon.ca/Steve-Jobs-Walter-Isaacson/dp/1451648545)
</Markdown>
<Markdown>
					- [Inside the Tornado](https://www.amazon.com/Inside-Tornado-Strategies-Developing-Hypergrowth/dp/0060745819)
</Markdown>
<Markdown>
					- [Motivation and Personality, 3rd Edition](https://www.amazon.com/Motivation-Personality-3rd-Abraham-Maslow/dp/0060419873)
</Markdown>
<Markdown>
					- [Letting Go](https://www.amazon.com/Letting-David-Hawkins-M-D-Ph-D/dp/1401945015)
</Markdown>
<Markdown>
					- [The River of Doubt](https://www.amazon.com/River-Doubt-Theodore-Roosevelts-Darkest/dp/0767913736)
</Markdown>
<Markdown>
					- [Tractatus Logico-Philosophicus](https://www.amazon.com/Tractatus-Logico-Philosophicus-Ludwig-Wittgenstein/dp/0486404455/ref=la_B000APXRL4_1_2?s=books&ie=UTF8&qid=1513406401&sr=1-2)
</Markdown>
<Markdown>
					- [Secrets of Antigravity Propulsion](https://www.amazon.ca/Secrets-Antigravity-Propulsion-Classified-Technology/dp/159143078X/ref=cm_cr_arp_d_product_top?ie=UTF8)
</Markdown>
<Markdown>
					- [Breakthrough Advertising](https://www.amazon.com/Breakthrough-Advertising-Eugene-M-Schwartz/dp/0887232981)
</Markdown>
<Markdown>
					- [Feeling Good: The New Mood Therapy](https://www.amazon.com/Feeling-Good-New-Mood-Therapy/dp/0380731762/ref=sr_1_1?s=books&ie=UTF8&qid=1507528679&sr=1-1&keywords=thinking+and+feeling+good)
</Markdown>
<Markdown>
					- [Reminiscences (Bluejacket Books)](https://www.amazon.com/Reminiscences-Bluejacket-Books-Douglas-MacArthur/dp/1557504830)
</Markdown>

<Markdown>
					- [Mastering the Rockefeller Habits](http://www.amazon.ca/Mastering-Rockefeller-Habits-Increase-Growing/dp/0978774949)
</Markdown>
<Markdown>
					- [Benjamin Franklin: An American Life](http://www.amazon.com/Benjamin-Franklin-An-American-Life/dp/074325807X)
</Markdown>
<Markdown>
					- [Emotional Intelligence: Why It Can Matter More Than IQ](https://www.amazon.com/Emotional-Intelligence-Matter-More-Than/dp/055338371X)
</Markdown>
<Markdown>
					- [Strategy Maps: Converting Intangible Assets into Tangible Outcomes](https://www.amazon.com/Strategy-Maps-Converting-Intangible-Tangible/dp/1591391342)
</Markdown>
<Markdown>
					- [Living Beautifully: with Uncertainty and Change](https://www.amazon.com/Living-Beautifully-Uncertainty-Pema-Chodron/dp/1611800765)
</Markdown>
<Markdown>
					- [Machine Learning Methods for Planning](https://www.amazon.com/Machine-Learning-Methods-Planning-Kaufmann/dp/1558602488)
</Markdown>
<Markdown>
					- [The House of Dimon](https://www.amazon.com/House-Dimon-JPMorgans-Jamie-Financial/dp/0470924691)
</Markdown>
<Markdown>
					- [The House of Morgan](https://www.amazon.com/House-Morgan-American-Banking-Dynasty/dp/0802144659)
</Markdown>
<Markdown>
					- [Howard Hughes: His Life And Madness](https://www.amazon.ca/gp/product/0393326020/ref=s9_simh_gw_p14_d0_i2?pf_rd_m=A3DWYIK6Y9EEQB&pf_rd_s=desktop-1&pf_rd_r=P71AME9F2NBWTNJGS3HC&pf_rd_t=36701&pf_rd_p=2055621862&pf_rd_i=desktop)
</Markdown>
<Markdown>
					- [Head First Design Patterns](http://shop.oreilly.com/product/9780596007126.do)
</Markdown>
<Markdown>
					- [Revitalizing a Nation](https://www.amazon.co.uk/Revitalizing-Nation-Statement-Opinions-Pronouncements/dp/1494010453)
</Markdown>
<Markdown>
					- [General MacArthur Wisdom and Visions](https://www.amazon.com/General-MacArthur-Wisdom-Visions-Douglas/dp/1681624109)
</Markdown>
<Markdown>
					- [The Monk and the Riddle](https://hbr.org/product/monk-and-the-riddle-the-art-of-creating-a-life-whi/an/6447-PBK-ENG)
</Markdown>
<Markdown>
					- [Answer to History](https://www.amazon.com/Answer-History-Mohammad-Reza-Pahlavi/dp/0812827554)
</Markdown>
<Markdown>
					- [The Art of the Start 2.0](http://guykawasaki.com/books/the-art-of-the-start/)
</Markdown>
<Markdown>
					- [Managing Oneself](https://www.amazon.com/Managing-Oneself-Harvard-Business-Classics-ebook/dp/B00TXS49UW/ref=pd_sim_351_1?_encoding=UTF8&psc=1&refRID=0EY4FC6H090DPY83MFAM)
</Markdown>
<Markdown>
					- [Negotiating the Impossible](https://www.amazon.com/Negotiating-Impossible-Deadlocks-Resolve-Conflicts/dp/1520014635)
</Markdown>
<Markdown>
					- [Making Ideas Happen](https://www.amazon.com/Making-Ideas-Happen-Overcoming-Obstacles/dp/1591844118)
</Markdown>
<Markdown>
					- [When I Stop Talking, You'll Know I'm Dead](https://www.amazon.com/When-Stop-Talking-Youll-Know-ebook/dp/B00351DSRI/ref=pd_sim_351_2?_encoding=UTF8&psc=1&refRID=0EY4FC6H090DPY83MFAM)
</Markdown>
<Markdown>
					- [A Manual for Living](https://www.amazon.com/Manual-Living-Little-Book-Wisdom/dp/0062511114)
</Markdown>
<Markdown>
					- [Total Recall: My Unbelievably True Life Story](https://www.amazon.com/Total-Recall-Unbelievably-True-Story-ebook/dp/B006VGGAC4/ref=la_B000AP7VZW_1_3?s=books&ie=UTF8&qid=1507483085&sr=1-3)
</Markdown>
<Markdown>
					- [Behind The Cloud](https://www.goodreads.com/book/show/6659874-behind-the-cloud)
</Markdown>
<Markdown>
					- [Rules for Revolutionaries](http://guykawasaki.com/books/rules-for-revolutionaries/)
</Markdown>
<Markdown>
					- [The 4-Hour Workweek](http://www.goodreads.com/book/show/368593.The_4_Hour_Workweek?from_search=true&search_version=service)
</Markdown>
<Markdown>
					- [Sam Walton: Made In America](http://www.amazon.ca/Sam-Walton-Made-America/dp/0553562835/ref=sr_1_1?ie=UTF8&qid=1455866056&sr=8-1&keywords=Sam+Walton%2C+Made+in+America)
</Markdown>
<Markdown>
					- [Human Happiness](https://www.goodreads.com/book/show/5884440-human-happiness)
</Markdown>
<Markdown>
					- [Pensées](https://www.goodreads.com/book/show/449407.Pens_es)
</Markdown>
<Markdown>
					- [Panzer Leader](https://www.amazon.com/Panzer-Leader-Heinz-Guderian/dp/0306811014/ref=la_B001ITYGCU_1_1?s=books&ie=UTF8&qid=1513398393&sr=1-1)
</Markdown>
<Markdown>
					- [The $100 Startup](http://www.barnesandnoble.com/w/the-100-startup-chris-guillebeau/1110776519)
</Markdown>
<Markdown>
					- [Enchantment](http://guykawasaki.com/books/enchantment/)
</Markdown>
<Markdown>
					- [Not Fade Away](https://www.amazon.com/Not-Fade-Away-Short-Lived/dp/006073731X)
</Markdown>
<Markdown>
					- [Built To Last](https://www.amazon.com/Built-Last-Successful-Visionary-Essentials/dp/0060516402)
</Markdown>
<Markdown>
					- [Fermat's Enigma: The Epic Quest to Solve the World's Greatest Mathematical Problem](https://www.amazon.com/Fermats-Enigma-Greatest-Mathematical-Problm/dp/0385493622)
</Markdown>
<Markdown>
					- [On Immunity: An Inoculation](https://www.amazon.com/Immunity-Inoculation-Eula-Biss/dp/1555977200/ref=pd_sim_14_6?_encoding=UTF8&psc=1&refRID=X9A1NFYX3HZAD6E5GJB9)
</Markdown>
<Markdown>
					- [The Better Angels of Our Nature](https://www.amazon.com/Better-Angels-Our-Nature-Violence/dp/0143122010/ref=pd_sim_14_5?_encoding=UTF8&psc=1&refRID=X9A1NFYX3HZAD6E5GJB9)
</Markdown>
<Markdown>
					- [Tap Dancing to Work](https://www.amazon.com/Tap-Dancing-Work-Practically-Everything/dp/1591846803/ref=pd_sim_14_3?_encoding=UTF8&psc=1&refRID=X9A1NFYX3HZAD6E5GJB9)
</Markdown>
<Markdown>
					- [Stress Test](https://www.amazon.com/Stress-Test-Reflections-Financial-Crises/dp/0804138613/ref=pd_sim_14_7?_encoding=UTF8&psc=1&refRID=X9A1NFYX3HZAD6E5GJB9)
</Markdown>
<Markdown>
					- [Business Adventures](https://www.amazon.com/Business-Adventures-Twelve-Classic-Street/dp/1497644895/ref=pd_sim_14_12?_encoding=UTF8&psc=1&refRID=X9A1NFYX3HZAD6E5GJB9)
</Markdown>
<Markdown>
					- [The 80/20 Principle](https://www.amazon.com/80-20-Principle-Secret-Achieving/dp/0385491743)
</Markdown>
<Markdown>
					- [The Essential Gandhi](https://www.amazon.com/Essenthttps://www.amazon.com/Immunity-Inoculation-Eula-Biss/dp/1555977200/ref=pd_sim_14_6?_encoding=UTF8&psc=1&refRID=X9A1NFYX3HZAD6E5GJB9)
</Markdown>

<Markdown>
					- [Marcus Aurelius: A Life](https://www.amazon.com/Marcus-Aurelius-Life-Frank-McLynn/dp/0306818302)
</Markdown>
<Markdown>
					- [The Autobiography of Malcolm X: As Told to Alex Haley](https://www.amazon.com/Autobiography-Malcolm-Told-Alex-Haley/dp/0345350685/ref=sr_1_1?s=books&ie=UTF8&qid=1509761322&sr=1-1&keywords=malcolm+x)
</Markdown>
<Markdown>
					- [Mastery](https://www.amazon.com/Mastery-Robert-Greene/dp/014312417X)
</Markdown>
<Markdown>
					- [Essentialism: The Disciplined Pursuit of Less](https://www.amazon.com/Essentialism-Disciplined-Pursuit-Greg-McKeown/dp/0804137382)
</Markdown>
<Markdown>
					- [Selling with Noble Purpose](https://www.amazon.com/Selling-Noble-Purpose-Drive-Revenue/dp/1118408098)
</Markdown>
<Markdown>
					- [The Art of Choosing](https://www.amazon.com/Art-Choosing-Sheena-Iyengar/dp/0446504114)
</Markdown>
<Markdown>
					- [Snow Crash](https://www.goodreads.com/book/show/830.Snow_Crash)
</Markdown>
<Markdown>
					- [The Coldest Winter](https://www.amazon.com/Coldest-Winter-America-Korean-War/dp/0786888628)
</Markdown>
<Markdown>
					- [Predictably Irrational](https://www.amazon.com/Predictably-Irrational-Revised-Expanded-Decisions/dp/0061353248)
</Markdown>
<Markdown>
					- [The Mystery of Capital](https://www.amazon.com/Mystery-Capital-Capitalism-Triumphs-Everywhere/dp/0465016154)
</Markdown>
<Markdown>
					- [Founders at Work](https://www.amazon.com/Founders-Work-Stories-Startups-Early-ebook/dp/B009IXMK4O)
</Markdown>
<Markdown>
					- [Ready Player One](https://www.goodreads.com/book/show/9969571-ready-player-one)
</Markdown>
<Markdown>
					- [Liar's Poker](https://www.amazon.comLiars-Poker-Norton-Paperback-Michael/dp/039333869X)
</Markdown>
<Markdown>
					- [Jab, Jab, Jab, Right Hook](https://www.amazon.com/Jab-Right-Hook-Story-Social/dp/006227306X)
</Markdown>
<Markdown>
					- [Getting Real](https://basecamp.com/about/books/Getting%20Real.pdf)
</Markdown>
<Markdown>
					- [Endurance](https://www.amazon.com/Endurance-Shackletons-Incredible-Alfred-Lansing/dp/0465062881)
</Markdown>
<Markdown>
					- [Fifth Business](https://www.amazon.com/Business-Deptford-Trilogy-Robertson-Davies/dp/0141186151)
</Markdown>
<Markdown>
					- [The Professor, the Banker, and the Suicide King](https://www.goodreads.com/book/show/297764.The_Professor_the_Banker_and_the_Suicide_King)
</Markdown>
<Markdown>
					- [Of Anger (Perfect Library)](https://www.amazon.com/Anger-Perfect-Library-Seneca-Younger/dp/151212723X/ref=sr_1_2?ie=UTF8&qid=1507082918&sr=8-2&keywords=seneca+anger)
</Markdown>

<Markdown>
					- [A Fighting Chance](https://www.amazon.com/Fighting-Chance-Elizabeth-Warren/dp/1627790527)
</Markdown>
<Markdown>
					- [The New New Thing: A Silicon Valley Story](https://www.amazon.com/New-Thing-Silicon-Valley-Story/dp/0393347818/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=JATCTFZYP4EXG50FB3BJ)
</Markdown>
<Markdown>
					- [Life 3.0: Being Human in the Age of Artificial Intelligence](https://www.goodreads.com/book/show/34272565-life-3-0)
</Markdown>
<Markdown>
					- [The Way Of Perfection](http://www.amazon.com/gp/product/0486484513/ref=pd_lpo_sbs_dp_ss_1?pf_rd_p=1944687602&pf_rd_s=lpo-top-stripe-1&pf_rd_t=201&pf_rd_i=0385065396&pf_rd_m=ATVPDKIKX0DER&pf_rd_r=1BPXCK0NG6RKW8BYY8RP)
</Markdown>
<Markdown>
					- [The Power of Habit](http://www.amazon.com/The-Power-Habit-What-Business/dp/081298160X)
</Markdown>
<Markdown>
					- [Fifty years with the Golden Rule](https://www.amazon.com/Fifty-years-Golden-Rule-Penney/dp/B0007DESEE)
</Markdown>
<Markdown>
					- [The Spiritual Journey of J. C. Penney](https://www.amazon.com/Spiritual-Journey-J-C-Penney/dp/1582440387/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=X3M0BQ20V86V8KAW194B)
</Markdown>
<Markdown>
					- [Napoleon: A Life](https://www.amazon.com/Napoleon-Life-Andrew-Roberts/dp/0143127853)
</Markdown>
<Markdown>
					- [Thinking in Systems: A Primer](https://www.goodreads.com/book/show/3828902-thinking-in-systems)
</Markdown>
<Markdown>
					- [The Compound Effect](https://www.amazon.com/Compound-Effect-Darren-Hardy/dp/159315724X/ref=pd_cp_14_4?_encoding=UTF8&pd_rd_i=159315724X&pd_rd_r=X3M0BQ20V86V8KAW194B&pd_rd_w=wOpaS&pd_rd_wg=FuCS7&psc=1&refRID=X3M0BQ20V86V8KAW194B)
</Markdown>
<Markdown>
					- [A Dozen Lessons for Entrepreneurs](https://cup.columbia.edu/book/a-dozen-lessons-for-entrepreneurs/9780231184823)
</Markdown>
<Markdown>
					- [Without Their Permission: How the 21st Century Will Be Made, Not Managed](https://www.amazon.com/Without-Their-Permission-Century-Managed/dp/1455520020)
</Markdown>
<Markdown>

					![alt tag](https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.goodreads.com%2Freview%2Fshow%2F521007760&psig=AOvVaw0UxUyd73lhp7AVj_A3pbw1&ust=1626653888702000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCNCYpYys6_ECFQAAAAAdAAAAABAD)
</Markdown>
<Markdown>

					- [Revolution for Dummies: Laughing Through the Arab Spring](https://www.amazon.com/Revolution-Dummies-Laughing-Through-Spring/dp/0062446894/ref=sr_1_4?s=books&ie=UTF8&qid=1507131932&sr=1-4&keywords=arab+spring)
</Markdown>
<Markdown>
					- [Dark Night of the Soul](http://www.amazon.com/Dark-Night-Dover-Thrift-Editions/dp/0486426939)
</Markdown>
<Markdown>
					- [The Subtle Art of Not Giving a F*ck](https://www.goodreads.com/book/show/28257707-the-subtle-art-of-not-giving-a-f-ck)
</Markdown>
<Markdown>
					- [The Wealth of Humans](https://www.amazon.com/Wealth-Humans-Status-Twenty-first-Century/dp/1250075807)
</Markdown>
<Markdown>
					- [The Sovereign Individual](https://www.amazon.com/Sovereign-Individual-Mastering-Transition-Information/dp/0684832720)
</Markdown>
<Markdown>

					- [Master and Commander](http://www.goodreads.com/book/show/77430.Master_and_Commander)
</Markdown>
<Markdown>
					- [The Hard Things About Hard Things](http://www.amazon.com/The-Hard-Thing-About-Things/dp/0062273205)
</Markdown>
<Markdown>
					- [Maverick: The Success Story Behind the World's Most Unusual Workplace](http://www.goodreads.com/book/show/32994.Maverick)
</Markdown>
<Markdown>
					- [Higher Than the Top](http://www.amazon.ca/Higher-Than-Top-Redenbacher-Bowerman/dp/0687170028)
</Markdown>
<Markdown>
					- [The First Tycoon: The Epic Life of Cornelius Vanderbilt](https://www.amazon.com/First-Tycoon-Epic-Cornelius-Vanderbilt/dp/1400031745)
</Markdown>
<Markdown>
					- [Rainmaking Conversations](https://www.amazon.com/Rainmaking-Conversations-Influence-Persuade-Situation/dp/0470922230)
</Markdown>
<Markdown>
					- [Reinventing American Health Care](https://www.amazon.com/Reinventing-American-Health-Care-Outrageously-ebook/dp/B00G1SD7BE)
</Markdown>
<Markdown>
					- [The Believing Brain](https://www.amazon.com/Believing-Brain-Conspiracies-How-Construct-Reinforce/dp/1250008808)
</Markdown>
<Markdown>
					- [The Daily Stoic](https://www.goodreads.com/book/show/29093292-the-daily-stoic)
</Markdown>
<Markdown>
					- [The Tao of Leadership](http://www.amazon.ca/Tao-Leadership-John-Heider/dp/1623860199/ref=sr_1_1?s=books&ie=UTF8&qid=1450596128&sr=1-1&keywords=the+tao+of+leadership)
</Markdown>
<Markdown>
					- [Design, Form, and Chaos](http://www.amazon.ca/Design-Form-Chaos-Paul-Rand/dp/0300055536)
</Markdown>
<Markdown>
					- [Non Violent Communication](http://www.amazon.ca/Nonviolent-Communication-Language-Life-Changing-Relationships/dp/189200528X/ref=sr_1_1?s=books&ie=UTF8&qid=1450596174&sr=1-1&keywords=non+violent+communication)
</Markdown>
<Markdown>
					- [15 Commitments of Conscious Leadership](http://www.amazon.ca/15-Commitments-Conscious-Leadership-Sustainable-ebook/dp/B00R3MHWUE/ref=sr_1_1?s=books&ie=UTF8&qid=1450596204&sr=1-1&keywords=the+15+commitments+of+conscious+leadership)
</Markdown>
<Markdown>
					- [Margaret Thatcher](https://www.amazon.com/dp/0297851152/?tag=mh0b-20&hvadid=3524937691&hvqmt=b&hvbmt=bb&hvdev=c&ref=pd_sl_g8sx1qnsv_b)
</Markdown>
<Markdown>
					- [Bargaining Games](https://www.amazon.com/Bargaining-Games-approach-strategic-negotiations/dp/0688128378)
</Markdown>
<Markdown>
					- [The Prince](https://www.amazon.com/Prince-Dover-Thrift-Editions/dp/0486272745)
</Markdown>
<Markdown>
					- [The Facebook Effect](http://www.amazon.ca/The-Facebook-Effect-Company-Connecting/dp/1439102120)
</Markdown>
<Markdown>
					- [From Lascaux to Brooklyn](http://www.amazon.ca/From-Lascaux-Brooklyn-Paul-Rand/dp/0300066767)
</Markdown>
<Markdown>
					- [The Score Takes Care of Itself: My Philosophy of Leadership](http://www.amazon.ca/The-Score-Takes-Care-Itself-ebook/dp/B002G54Y04)
</Markdown>
<Markdown>
					- [The Tao of Programming](http://www.mit.edu/~xela/tao.html)
</Markdown>
<Markdown>
					- [Netflixed](https://www.amazon.com/Netflixed-Epic-Battle-Americas-Eyeballs-ebook/dp/B007X5ZE4W)
</Markdown>
<Markdown>
					- [Ready Player One: A Novel](https://www.amazon.com/Ready-Player-One-Ernest-Cline/dp/0307887448)
</Markdown>
<Markdown>
					- [High Output Management](http://www.amazon.ca/High-Output-Management-Andrew-Grove/dp/0679762884)
</Markdown>
<Markdown>
					- [Disrupt Yourself](http://www.amazon.ca/Disrupt-Yourself-Putting-Disruptive-Innovation/dp/1629560529)
</Markdown>
<Markdown>
					- [Managerial Breakthrough: The Classic Book on Improving Management Performance](http://www.amazon.com/Managerial-Breakthrough-Improving-Management-Performance/dp/0070340374)
</Markdown>
<Markdown>
					- [Things a Computer Scientist Rarely Talks About](http://www.amazon.com/Things-Computer-Scientist-Language-Information/dp/157586326X)
</Markdown>
<Markdown>
					- [Food Politics: What Everyone Needs to Know](https://www.amazon.com/Food-Politics-Everyone-Needs-Know%C2%AE/dp/0199322384/ref=pd_sim_14_2?_encoding=UTF8&psc=1&refRID=6Z2XE6KKDNWRW07Q2EKY&dpID=51qG5LXnJKL&preST=_SY291_BO1,204,203,200_QL40_&dpSrc=detail)
</Markdown>
<Markdown>
					- [JS Design Patterns](https://addyosmani.com/resources/essentialjsdesignpatterns/book/)
</Markdown>
<Markdown>
					- [The CEO Next Door](https://www.amazon.com/CEO-Next-Door-Behaviors-World-Class/dp/1101906499)
</Markdown>
<Markdown>
					- [One Billion Hungry: Can We Feed the World?](https://www.amazon.com/One-Billion-Hungry-Feed-World/dp/0801478022/ref=pd_sim_14_4?_encoding=UTF8&psc=1&refRID=6Z2XE6KKDNWRW07Q2EKY)
</Markdown>
<Markdown>
					- [The Art of Problem Solving, Vol. 1: The Basics](http://www.amazon.com/Art-Problem-Solving-Vol-Basics/dp/0977304566/ref=sr_1_1?s=books&ie=UTF8&qid=1456172515&sr=1-1&keywords=the+art+of+problem+solving)
</Markdown>
<Markdown>
					- [Hatching Twitter: A True Story of Money, Power, Friendship, and Betrayal](http://www.amazon.com/Hatching-Twitter-Story-Friendship-Betrayal/dp/1591847087)
</Markdown>
<Markdown>
					- [The No Asshole Rule](http://www.amazon.com/gp/product/B000OT8GV2/ref=dp-kindle-redirect?ie=UTF8&btkr=1)
</Markdown>
<Markdown>
					- [Scaling Up: How a Few Companies Make It...and Why the Rest Don't](http://www.amazon.ca/Scaling-Up-Companies-Rockefeller-Habits/dp/0986019526/ref=pd_cp_14_1/189-3887315-9587333?ie=UTF8&refRID=0AHGE0R30E8M4ZNE8H0V)
</Markdown>
<Markdown>
					- [Sprint: How to Solve Big Problems and Test New Ideas in Just Five Days](http://www.amazon.com/Sprint-Solve-Problems-Test-Ideas/dp/150112174X/ref=as_li_ss_tl?ie=UTF8&qid=1456949088&sr=8-1&keywords=sprint+book&linkCode=sl1&tag=sprint-medium-20&linkId=897397d490e660f289a446c02a9ff17f)
</Markdown>
<Markdown>
					- [Geekonomics: The Real Cost of Insecure Software](http://www.amazon.com/Geekonomics-Real-Insecure-Software-paperback/dp/0321735978)
</Markdown>
<Markdown>
					- [Emotions Revealed, Second Edition](https://www.amazon.com/Emotions-Revealed-Second-Recognizing-Communication/dp/0805083391)
</Markdown>
<Markdown>
					- [The Wisdom of Psychopaths](https://www.amazon.com/Wisdom-Psychopaths-Saints-Killers-Success/dp/0374533989)
</Markdown>
<Markdown>
					- [Rework](http://www.amazon.com/Rework-Jason-Fried/dp/0307463745)
</Markdown>
<Markdown>
					- [Agile!: The Good, the Hype and the Ugly](http://www.amazon.com/Agile-The-Good-Hype-Ugly/dp/3319051547)
</Markdown>
<Markdown>
					- [The Design of Everyday Things: Revised and Expanded Edition](http://www.amazon.com/The-Design-Everyday-Things-Expanded/dp/0465050654)
</Markdown>
<Markdown>
					- [Facts and Fallacies of Software Engineering](http://www.amazon.com/Facts-Fallacies-Software-Engineering-Robert/dp/0321117425)
</Markdown>
<Markdown>
					- [The Sovereign Individual: Mastering the Transition to the Information Age](https://www.amazon.com/Sovereign-Individual-Mastering-Transition-Information/dp/0684832720)
</Markdown>
<Markdown>
					- [Dawn of the New Everything](https://www.amazon.com/Dawn-New-Everything-Encounters-Reality/dp/1627794093)
</Markdown>
<Markdown>
					- [Manhattan Beach](https://www.goodreads.com/book/show/34467031-manhattan-beach)
</Markdown>
<Markdown>
					- [Thank You for Being Late](https://www.amazon.com/Thank-You-Being-Late-Accelerations/dp/0374273537)
</Markdown>
<Markdown>
					- [The Fourth Industrial Revolution](https://www.amazon.ca/Fourth-Industrial-Revolution-Klaus-Schwab/dp/1944835008)
</Markdown>
<Markdown>
					- [To Sell Is Human](https://www.goodreads.com/book/show/13593553-to-sell-is-human)
</Markdown>
<Markdown>
					- [The Closer’s Survival Guide](https://www.amazon.com/Closers-Survival-Guide-Third/dp/B00C7ISUZA)
</Markdown>
<Markdown>
					- [The Victorian Internet](https://www.amazon.com/Victorian-Internet-Remarkable-Nineteenth-line/dp/162040592X/ref=as_li_ss_tl?ie=UTF8&qid=1498066189&sr=8-1&keywords=the+victorian+internet&linkCode=sl1&tag=colinfgee-20&linkId=556ebaabb727b81a77c9b3943e9f80d7)
</Markdown>
<Markdown>
					- [The Psychology of Computer Programming: Silver Anniversary Edition](http://www.amazon.com/The-Psychology-Computer-Programming-Anniversary/dp/0932633420)
</Markdown>
<Markdown>
					- [Janesville: An American Story](https://www.amazon.com/Janesville-American-Story-Amy-Goldstein/dp/1501102230)
</Markdown>
<Markdown>
					- [Radical Technologies: The Design of Everyday Life](https://www.amazon.ca/Radical-Technologies-Design-Everyday-Life/dp/178478043X)
</Markdown>
<Markdown>
					- [Do I Make Myself Clear?: Why Writing Well Matters](https://www.amazon.com/Do-Make-Myself-Clear-Writing/dp/0316277177)
</Markdown>
<Markdown>
					- [Adversaries into Allies](https://www.amazon.ca/Adversaries-into-Allies-Ultimate-Influence/dp/1591848164)
</Markdown>
<Markdown>
					- [Peopleware: Productive Projects and Teams](http://www.amazon.com/Peopleware-Productive-Projects-Teams-Edition/dp/0321934113)
</Markdown>
<Markdown>
					- [Infantry Attacks](https://www.amazon.com/Infantry-Attacks-Erwin-Rommel/dp/B002C40AUW/ref=la_B001H6J32W_1_4?s=books&ie=UTF8&qid=1504396732&sr=1-4)
</Markdown>
<Markdown>
					- [Valley Boy: The Education of Tom Perkins](http://www.amazon.com/Valley-Boy-Education-Tom-Perkins/dp/1592404030)
</Markdown>
<Markdown>
					- [The Monk Who Sold His Ferrari](https://www.amazon.ca/Monk-Who-Sold-His-Ferrari/dp/0006385125)
</Markdown>
<Markdown>
					- [Think and Grow Rich: Unabridged Text of First Edition](https://www.amazon.ca/Think-Grow-Rich-Unabridged-First/dp/1578988632)
</Markdown>
<Markdown>
					- [Damn Good Advice](https://www.amazon.com/Damn-Good-Advice-People-Talent/dp/0714863483/ref=sr_1_1?s=books&ie=UTF8&qid=1533518191&sr=1-1&keywords=Damn-Good-Advice-People-Talent)
</Markdown>
<Markdown>
					- [Writing the Breakout Novel](https://www.amazon.com/gp/product/158297182X/ref=as_at/?imprToken=miNUglkpH830iCi4J0NH-Q&slotNum=3&ie=UTF8&camp=1789&creative=9325&creativeASIN=158297182X&linkCode=w61&tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [How to Write a Damn Good Novel](https://www.amazon.com/gp/product/0312010443/ref=as_at/?imprToken=miNUglkpH830iCi4J0NH-Q&slotNum=2&ie=UTF8&camp=1789&creative=9325&creativeASIN=0312010443&linkCode=w61&tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [Made to Stick](https://www.amazon.com/gp/product/1400064287/ref=as_at/?imprToken=miNUglkpH830iCi4J0NH-Q&slotNum=1&ie=UTF8&camp=1789&creative=9325&creativeASIN=1400064287&linkCode=w61&tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [The First Five Pages](https://www.amazon.com/gp/product/068485743X/ref=as_at/?imprToken=miNUglkpH830iCi4J0NH-Q&slotNum=0&ie=UTF8&camp=1789&creative=9325&creativeASIN=068485743X&linkCode=w61&tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [Cold Calling Techniques](https://www.amazon.com/Cold-Calling-Techniques-That-Really/dp/1480572276)
</Markdown>
<Markdown>
					- [Charlie Munger: The Complete Investor](https://www.amazon.com/Charlie-Munger-Complete-Investor-Publishing/dp/023117098X)
</Markdown>
<Markdown>
					- [The Willpower Instinct: How Self-Control Works, Why It Matters, and What You Can Do to Get More of It](https://www.amazon.ca/Willpower-Instinct-Self-Control-Works-Matters/dp/1583335080)
</Markdown>
<Markdown>
					- [John Doeer's List](https://www.goodreads.com/list/show/34363.GSB_recommendations_to_John_Doerr)
</Markdown>
<Markdown>
					- [Flow: The Psychology of Optimal Experience](https://www.amazon.ca/Flow-Psychology-Experience-Mihaly-Csikszentmihalyi/dp/0061339202)
</Markdown>
<Markdown>
					- [Strategic Alliances and Marketing Partnerships](https://www.amazon.ca/Strategic-Alliances-Marketing-Partnerships-Collaboration/dp/0749454849)
</Markdown>
<Markdown>
					- [Make It Stick: The Science of Successful Learning](https://www.amazon.com/Make-Stick-Science-Successful-Learning/dp/0674729013)
</Markdown>
<Markdown>
					- [100 Plus](https://www.amazon.com/dp/0465063764/?tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [Bloodlands](https://www.amazon.com/dp/0465031471/?tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [Resurrection from the Underground](https://www.amazon.com/dp/1611860377/?tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [Sir Francis Bacon](https://www.amazon.com/dp/1451537638/?tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [The American Challenge](https://www.amazon.com/gp/product/B00KLDRT0U/ref=as_at/?imprToken=.aP561F4x93kcA.-KJke5Q&slotNum=1&ie=UTF8&camp=1789&creative=9325&creativeASIN=B00KLDRT0U&linkCode=w61&tag=farnamstreet-20&linkId=362Y4OOFY76BILHZ)
</Markdown>
<Markdown>
					- [The Great Illusion A Study of the Relation of Military Power to National Advantage](https://www.amazon.com/dp/161203652X/?tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [The Diamond Age](https://www.amazon.com/dp/0553380966/?tag=farnamstreet-20)
</Markdown>
<Markdown>
					- [Simple Rules: How to Thrive in a Complex World ](https://www.amazon.com/Simple-Rules-Thrive-Complex-World/dp/0544705203)
</Markdown>
<Markdown>
					- [The Elements of Investing: Easy Lessons for Every Investor](https://www.amazon.com/Elements-Investing-Lessons-Every-Investor/dp/1118484878)
</Markdown>
<Markdown>
					- [Maps of Meaning: The Architecture of Belief](https://www.goodreads.com/book/show/2218102.Maps_of_Meaning)
</Markdown>
<Markdown>
					- [Man's Search for Meaning](https://www.amazon.ca/Mans-Search-Meaning-Viktor-Frankl/dp/080701429X)
</Markdown>
<Markdown>
					- [The 48 Laws of Power](https://www.amazon.ca/48-Laws-Power-Robert-Greene/dp/0140280197)
</Markdown>
<Markdown>
					- [Art & Fear: Observations On the Perils (and Rewards)of Artmaking](https://www.amazon.com/Art-Fear-Observations-Rewards-Artmaking/dp/0961454733/ref=pd_bxgy_14_img_2?_encoding=UTF8&psc=1&refRID=ZMG9JQ1MKF7Y1JDRZ96A)
</Markdown>

<Markdown>
					- [Leadership and Self-Deception: Getting Out of the Box](https://www.goodreads.com/book/show/180463.Leadership_and_Self_Deception?)
</Markdown>
<Markdown>
					- [How Will You Measure Your Life?](https://www.goodreads.com/book/show/13425570-how-will-you-measure-your-life)
</Markdown>
<Markdown>
					- [Start with Why: How Great Leaders Inspire Everyone to Take Action](https://www.goodreads.com/book/show/7108725-start-with-why)
</Markdown>
<Markdown>
					- [The God Theory: Universes, Zero-point Fields, and What's Behind It All](https://www.amazon.com/God-Theory-Universes-Zero-point-Fields/dp/1578634369/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=PB2FGA37V6CCQX2JGS1Q)
</Markdown>
<Markdown>
					- [World Order](https://www.amazon.com/World-Order-Henry-Kissinger/dp/0143127713)
</Markdown>
<Markdown>
					- [Diplomacy](https://www.amazon.com/Diplomacy-Touchstone-Book-Henry-Kissinger/dp/0671510991)
</Markdown>
<Markdown>
					- [Quantum Enigma: Physics Encounters Consciousness](https://www.amazon.com/Quantum-Enigma-Physics-Encounters-Consciousness/dp/0199753814/ref=pd_lpo_sbs_14_t_1?_encoding=UTF8&psc=1&refRID=PB2FGA37V6CCQX2JGS1Q)
</Markdown>
<Markdown>
					- [Biocentrism: How Life and Consciousness are the Keys to Understanding the True Nature of the Universe](https://www.amazon.com/Biocentrism-Consciousness-Understanding-Nature-Universe/dp/1935251740)
</Markdown>
<Markdown>
					- [The Art Spirit](https://www.amazon.com/Art-Spirit-Robert-Henri/dp/0465002633)
</Markdown>
<Markdown>
					- [Boyd: The Fighter Pilot Who Changed the Art of War](https://www.goodreads.com/book/show/38840.Boyd)
</Markdown>
<Markdown>
					- [Linked: How Everything Is Connected to Everything Else and What It Means for Business, Science, and Everyday Life ](https://www.amazon.com/Linked-Everything-Connected-Business-Everyday/dp/0465085733)
</Markdown>
<Markdown>
					- [Building Great Software Engineering Teams: Recruiting, Hiring, and Managing Your Team from Startup to Success](https://www.goodreads.com/book/show/26341904-building-great-software-engineering-teams?ac=1&from_search=true)
</Markdown>
<Markdown>
					- [The End of Average: How We Succeed in a World That Values Sameness](https://www.goodreads.com/book/show/24186666-the-end-of-average?ac=1&from_search=true)
</Markdown>
<Markdown>
					- [Anger, Mercy, Revenge](https://www.amazon.com/Revenge-Complete-Lucius-Annaeus-Seneca/dp/0226748421/ref=sr_1_1?ie=UTF8&qid=1507082918&sr=8-1&keywords=seneca+anger)
</Markdown>
<Markdown>
					- [Alexander Hamilton](https://www.goodreads.com/book/show/16130.Alexander_Hamilton?ac=1&from_search=true)
</Markdown>
<Markdown>
					- [The Intelligent Investor](https://www.amazon.com/Intelligent-Investor-Definitive-Investing-Essentials/dp/0060555661)
</Markdown>
<Markdown>
					- [Work Rules!: Insights from Inside Google That Will Transform How You Live and Lead](https://www.amazon.com/Work-Rules-Insights-Inside-Transform/dp/1455554790)
</Markdown>
<Markdown>
					- [Algorithms to Live By: The Computer Science of Human Decisions ](https://www.amazon.com/Algorithms-Live-Computer-Science-Decisions/dp/1627790365)
</Markdown>
<Markdown>
					- [Team of Rivals: The Political Genius of Abraham Lincoln](https://www.amazon.com/Team-Rivals-Political-Abraham-Lincoln/dp/0743270754/)
</Markdown>
<Markdown>
					- [The Beginning of Infinity: Explanations That Transform the World](https://www.amazon.com/Beginning-Infinity-Explanations-Transform-World-ebook/dp/B005DXR5ZC/#nav-subnav)
</Markdown>
<Markdown>
					- [Our Mathematical Universe: My Quest for the Ultimate Nature of Reality](https://www.amazon.com/dp/B00DXKJ2DA#nav-subnav)
</Markdown>
<Markdown>
					- [Cracking the PM Interview: How to Land a Product Manager Job in Technology](https://www.amazon.com/Cracking-PM-Interview-Product-Technology-ebook/dp/B00ISYMUR6#nav-subnav)
</Markdown>
<Markdown>
					- [The Four Steps to the Epiphany](https://www.amazon.com/Four-Steps-Epiphany-Steve-Blank/dp/0989200507?ie=UTF8&camp=1789&creative=9325&creativeasin=0989200507&linkcode=as2&redirect=true&ref_=as_li_tf_tl&tag=wwwsteveblank-20)
</Markdown>
<Markdown>
					- [Growth Hacker Marketing: A Primer on the Future of PR, Marketing, and Advertising](https://www.amazon.ca/Growth-Hacker-Marketing-Primer-Advertising/dp/1591847389)
</Markdown>
<Markdown>
					- [The Compleated Autobiography by Benjamin Franklin](https://www.amazon.com/gp/product/B000EQHWBU/ref=as_li_tl?ie=UTF8&tag=startreading-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B000EQHWBU&linkId=dbaf224510c2b1f2fd7646e2f06cc2d5)
</Markdown>
<Markdown>
					- [Mother Teresa: Come Be My Light: The Private Writings of the Saint of Calcutta](https://www.amazon.com/Mother-Teresa-Private-Writings-Calcutta-ebook/dp/B000UZJQD2)
</Markdown>
<Markdown>
					- [The Rise of Theodore Roosevelt](https://www.amazon.com/gp/product/B003TLSM70/ref=as_li_tl?ie=UTF8&tag=startreading-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B003TLSM70&linkId=580b1a7b5c5457b598adfddb1dd0bf03)
</Markdown>
<Markdown>
					- [Outliers: The Story of Success](https://www.amazon.com/gp/product/1600243916/ref=as_li_tl?ie=UTF8&tag=startreading-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1600243916&linkId=2a6c26306dffbf1c5ba15c377f9c2aa8)
</Markdown>
<Markdown>
					- [Swimming Across: A Memoir](https://www.amazon.com/Swimming-Across-Andrew-S-Grove/dp/0446529923)
</Markdown>
<Markdown>
					- [Revolution in The Valley](https://www.amazon.com/Revolution-The-Valley-Insanely-Great/dp/1449316247)
</Markdown>
<Markdown>
					- [The Dream Machine](https://www.amazon.com/Dream-Machine-Licklider-Revolution-Computing/dp/0670899763)
</Markdown>
<Markdown>
					- [Making Silicon Valley](https://www.amazon.com/Making-Silicon-Valley-Innovation-Technology/dp/0262622114)
</Markdown>
<Markdown>
					- [Season of the Witch](https://www.amazon.com/Season-Witch-Enchantment-Terror-Deliverance/dp/1439108242/)
</Markdown>
<Markdown>
					- [From Counterculture to Cyberculture](https://www.amazon.com/gp/product/0226817423/)
</Markdown>
<Markdown>
					- [Purple Cow](https://www.amazon.com/Purple-Cow-New-Transform-Remarkable/dp/1591843170)
</Markdown>
<Markdown>
					- [The Little Kingdom](https://www.amazon.com/Little-Kingdom-Private-Story-Computer/dp/0688039731/)
</Markdown>
<Markdown>
					- [Something Ventured](https://www.amazon.com/Something-Ventured-Tom-Perkins/dp/B0079ZWUW0/)
</Markdown>
<Markdown>
					- [Meditations](https://www.amazon.com/Meditations-Marcus-Aurelius/dp/1503280462/ref=pd_lpo_sbs_14_img_2?_encoding=UTF8&psc=1&refRID=EH8WTCNS18KBY73J3J30)
</Markdown>
<Markdown>
					- [Being Geek: The Software Developer's Career Handbook](https://www.amazon.com/Being-Geek-Software-Developers-Handbook/dp/0596155409/ref=la_B001JP9ZV0_1_3?s=books&ie=UTF8&qid=1513501026&sr=1-3)
</Markdown>
<Markdown>
					- [Alexander the Great's Art of Strategy](https://www.audible.com/pd/Business/Alexander-the-Greats-Art-of-Strategy-Audiobook/B002V8HGFG?source_code=GPAGBSH0508140001&mkwid=sNzDNHhyx_dc&pcrid=167183620276&pmt=&pkw=&product=B002V8HGFG&cvosrc=ppc%20cse.google%20shopping.195434700&cvo_crid=167183620276&cvo_pid=38352974140)
</Markdown>
<Markdown>
					- [Alexander the Great: Journey to the End of the Earth](https://play.google.com/store/books/details?id=xqtS4_856U8C&source=productsearch&utm_source=HA_Desktop_US&utm_medium=SEM&utm_campaign=PLA&pcampaignid=MKTAD0930BO1&gclid=CjwKCAjwuITNBRBFEiwA9N9YELI87UOxxRS3c2M_sZle7-B-omGx5bG1idaacqWqus3AVmJqJTC9uxoCugwQAvD_BwE&gclsrc=aw.ds&dclid=CP7_3tyf9tUCFddrfgodhOsIgg)
</Markdown>
<Markdown>
					- [Satyagraha in South Africa](https://www.amazon.com/Satyagraha-South-Africa-M-K-Gandhi/dp/8172290411)
</Markdown>
<Markdown>
					- [The Chip](https://www.amazon.com/The-Chip-Americans-Microchip-Revolution/dp/0375758283)
</Markdown>
<Markdown>
					- [Option B: Facing Adversity, Building Resilience, and Finding Joy](https://www.amazon.com/Option-Adversity-Building-Resilience-Finding-ebook/dp/B01N8R5QD7)
</Markdown>
<Markdown>
					- [Computer Lib/Dream Machines](https://www.amazon.com/Computer-Lib-Dream-Machines/dp/0893470031)
</Markdown>
<Markdown>
					- [The Idea Factory](https://www.amazon.com/The-Idea-Factory-American-Innovation/dp/0143122797)
</Markdown>
<Markdown>
					- [I Seem to Be a Verb: Environment and Man's Future](https://www.amazon.com/Seem-Be-Verb-Environment-Future/dp/B0006CZBHO)
</Markdown>
<Markdown>
					- [Dealers of Lightning](https://www.amazon.com/Dealers-Lightning-Xerox-PARC-Computer/dp/0887309895)
</Markdown>
<Markdown>
					- [Genentech: The Beginnings of Biotech](https://www.amazon.com/Genentech-The-Beginnings-Biotech-Synthesis/dp/022604551X)
</Markdown>
<Markdown>
					- [Introduction to Algorithms, 3rd Edition (MIT Press)](https://www.amazon.com/Introduction-Algorithms-3rd-Thomas-Cormen/dp/0262033844/ref=sr_1_1?ie=UTF8&qid=1432914019&sr=8-1&keywords=clrs)
</Markdown>

<Markdown>
					- [Introduction to Distributed Algorithms](https://www.amazon.com/Introduction-Distributed-Algorithms-Gerard-Tel/dp/0521794838/ref=pd_rhf_dp_s_cp_2_GZP8?ie=UTF8&refRID=1WWMDXPA9NDRZDF7S9V0)
</Markdown>
<Markdown>
					- [Replication](http://www.springer.com/us/book/9783642112935)
</Markdown>
<Markdown>
					- [Perfect Software: And Other Illusions About Testing](https://www.amazon.co.uk/dp/0932633692)
</Markdown>
<Markdown>
					- [Ego Is the Enemy](https://www.amazon.ca/Ego-Enemy-Ryan-Holiday/dp/1591847818)
</Markdown>
<Markdown>
					- [Shoe Dog: A Memoir by the Creator of Nike](https://www.amazon.ca/Shoe-Dog-Memoir-Creator-Nike/dp/1501135910)
</Markdown>
<Markdown>
					- [Mindset: The New Psychology of Success](https://www.amazon.ca/Mindset-Psychology-Carol-S-Dweck-ebook/dp/B000FCKPHG)
</Markdown>
<Markdown>
					- [Mindshift](https://www.amazon.com/gp/product/1101982853/)
</Markdown>
<Markdown>
					- [Never Split the Difference](https://www.amazon.com/gp/product/0062407805/)
</Markdown>
<Markdown>
					- [The Effective Executive](https://www.amazon.ca/Effective-Executive-Definitive-Getting-Things/dp/0060833459)
</Markdown>
<Markdown>
					- [Poor Charlie's Almanack](https://www.goodreads.com/book/show/944652.Poor_Charlie_s_Almanack)
</Markdown>
<Markdown>
					- [An Unfinished Life: John F. Kennedy](https://www.amazon.com/Unfinished-Life-John-Kennedy-1917/dp/0316907928/ref=pd_sim_14_2?_encoding=UTF8&psc=1&refRID=TBMB413Q5KFG80NG8V8W)
</Markdown>
<Markdown>


					![alt tag](http://www.nasa.gov/centers/kennedy/images/content/618568main_1962-11-16-1600_946-710.jpg)
</Markdown>
<Markdown>


					- [Give and Take: Why Helping Others Drives Our Success](https://www.amazon.ca/Give-Take-Helping-Others-Success/dp/0143124986)
</Markdown>
<Markdown>
					- [Talking to Crazy](https://www.amazon.ca/Talking-Crazy-Irrational-Impossible-People/dp/0814436366)
</Markdown>
<Markdown>
					- [The Black Swan: Second Edition](https://www.amazon.com/Black-Swan-Improbable-Robustness-Fragility/dp/081297381X/ref=pd_lpo_sbs_14_t_0?_encoding=UTF8&psc=1&refRID=P9PM81WSVJJRH3SR3MRW)
</Markdown>
<Markdown>
					- [Strategy: A History](https://www.amazon.com/gp/product/0190229233?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Sell To Survive](https://www.amazon.com/Sell-Survive-Grant-Cardone/dp/1607252511)
</Markdown>
<Markdown>
					- [Re-Imagine! Business Excellence in a Disruptive Age](https://www.amazon.com/gp/product/078949647X/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=078949647X&linkCode=as2&tag=fuzzbug&linkId=VMNN3EYYZEYDQUL4)
</Markdown>
<Markdown>
					- [The Power of Starting Something Stupid](https://www.amazon.com/Power-Starting-Something-Stupid/dp/1609070097)
</Markdown>
<Markdown>
					- [The Big Moo](https://www.amazon.com/gp/product/1591841038/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1591841038&linkCode=as2&tag=fuzzbug&linkId=K364YYLJ6CT4EL3M)
</Markdown>
<Markdown>
					- [Setting the Table](https://www.amazon.com/gp/product/0060742763/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0060742763&linkCode=as2&tag=fuzzbug&linkId=LPHXHOTVGEJSM7NC)
</Markdown>
<Markdown>
					- [EntreLeadership](https://www.amazon.com/gp/product/1451617852/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1451617852&linkCode=as2&tag=fuzzbug&linkId=XLJTFCR6SFDJSOBV)
</Markdown>
<Markdown>
					- [Practicing the Power of Now](https://www.amazon.com/gp/product/1577311957/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1577311957&linkCode=as2&tag=fuzzbug&linkId=P4EMCRC6IMV7HSRC)
</Markdown>
<Markdown>
					- [Influence](https://www.amazon.com/gp/product/006124189X/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=006124189X&linkCode=as2&tag=fuzzbug&linkId=OAA3FB6CLFU7CI76)
</Markdown>
<Markdown>
					- [Positioning](https://www.amazon.com/gp/product/0071373586/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0071373586&linkCode=as2&tag=fuzzbug&linkId=VSDRD7O3IZR3U7CV)
</Markdown>
<Markdown>
					- [Let My People Go Surfing](https://www.amazon.com/gp/product/0143037838/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0143037838&linkCode=as2&tag=fuzzbug&linkId=JG2LBGKVMZOVKXZL)
</Markdown>
<Markdown>
					- [Freakonomics](https://www.barnesandnoble.com/w/freakonomics-steven-d-levitt/1100550563;jsessionid=448E061286690D0B8953AE067CA07D2C.prodny_store01-atgap10?ean=9780060731335&st=AFF&2sid=HarperCollins%20Publishers%20LLC_7252169_NA&sourceId=AFFHarperCollins%20Publishers%20LLCM000023#/)
</Markdown>
<Markdown>
					- [Man and His Symbols](https://www.amazon.com/Man-His-Symbols-Carl-Jung/dp/0440351839/ref=pd_lpo_sbs_14_t_2?_encoding=UTF8&psc=1&refRID=E0ZMZTEN2J223E78S15D)
</Markdown>
<Markdown>
					- [The Red Book](https://www.amazon.com/Red-Book-Philemon-C-Jung/dp/0393065677)
</Markdown>
<Markdown>
					- [The Turnaround Kid](https://www.amazon.com/Turnaround-Kid-Rescuing-Americas-Companies/dp/1400156122)
</Markdown>
<Markdown>
					- [Prediction Machines](https://www.amazon.com/Prediction-Machines-Economics-Artificial-Intelligence/dp/1633695670)
</Markdown>
<Markdown>
					- [Managing Oneself](https://www.amazon.com/gp/product/142212312X/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=142212312X&linkCode=as2&tag=fuzzbug&linkId=QXMNYFQTG64PAUAO)
</Markdown>
<Markdown>
					- [Zingerman's Guide to Giving Great Service](https://www.amazon.com/gp/product/1401301436/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1401301436&linkCode=as2&tag=fuzzbug&linkId=FJLMFL7K3HMRMDKR)
</Markdown>
<Markdown>
					- [Seeking Wisdom](https://www.amazon.com/gp/product/1578644283/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1578644283&linkCode=as2&tag=fuzzbug&linkId=U6SBIVHVEK4V7GE3)
</Markdown>
<Markdown>
					- [The Fifth Discipline](https://www.amazon.com/Fifth-Discipline-Practice-Learning-Organization/dp/0385517254/ref=pd_sim_14_4?_encoding=UTF8&psc=1&refRID=WW1K91VXMDR9Z9CZCZJG)
</Markdown>
<Markdown>
					- [Black Privilege](https://www.amazon.com/Black-Privilege-Opportunity-Comes-Create/dp/1501145304/ref=sr_1_1?tag=ryanholnet-20&s=books&ie=UTF8&qid=1497198898&sr=1-1&keywords=black+privilege+charlamagne)
</Markdown>
<Markdown>
					- [Perennial Seller](https://www.amazon.com/Perennial-Seller-Making-Marketing-Lasts/dp/0143109014?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [The New Strategic Selling](https://www.amazon.com/New-Strategic-Selling-Successful-Companies/dp/044669519X)
</Markdown>
<Markdown>
					- [Poor Richard's Almanack](https://www.youtube.com/watch?v=kYIsDFEvQHA)
</Markdown>
<Markdown>
					- [Blue Ocean Shift: Beyond Competing](https://www.amazon.com/Blue-Ocean-Shift-Competing-Confidence/dp/0316314048)
</Markdown>
<Markdown>
					- [The Phenomenon](https://www.amazon.com/Phenomenon-Pressure-Yips-Pitch-Changed/dp/1610396863?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Triggers](https://www.amazon.com/Triggers-Creating-Behavior-Lasts-Becoming-Person/dp/0804141231)
</Markdown>
<Markdown>
					- [Bold](https://www.amazon.com/Bold-Create-Wealth-Impact-World/dp/1476709580)
</Markdown>
<Markdown>
					- [Stealing Fire](https://www.amazon.com/Stealing-Fire-Maverick-Scientists-Revolutionizing/dp/0062429655)
</Markdown>
<Markdown>
					- [The Rise of Superman](https://www.amazon.com/Rise-Superman-Decoding-Ultimate-Performance/dp/1477800832/ref=pd_lpo_sbs_14_t_0?_encoding=UTF8&psc=1&refRID=XT351SXQNT82B3C9JZWQ&dpID=51Lr-FRqOYL&preST=_SY344_BO1,204,203,200_QL70_&dpSrc=detail)
</Markdown>
<Markdown>
					- [The Innovators](https://www.amazon.com/Innovators-Hackers-Geniuses-Created-Revolution/dp/1476708703)
</Markdown>
<Markdown>
					- [List of Books for First Time Managers](https://blog.chaddickerson.com/2018/01/09/best-books-for-new-first-time-managers/)
</Markdown>
<Markdown>
					- [Google Speaks](https://www.amazon.com/Google-Speaks-Greatest-Billionaire-Entrepreneurs/dp/047039854X)
</Markdown>
<Markdown>
					- [The Sales Acceleration Formula](https://www.amazon.com/Sales-Acceleration-Formula-Technology-Inbound/dp/1119047072)
</Markdown>
<Markdown>
					- [The Ultimate Sales Machine](https://www.amazon.com/Ultimate-Sales-Machine-Turbocharge-Relentless/dp/1591842158)
</Markdown>
<Markdown>
					- [Up from Slavery](https://www.amazon.com/Up-Slavery-Dover-Thrift-Editions/dp/0486287386/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Bodyguard of Lies: The Extraordinary True Story Behind D-Day](https://www.amazon.com/Bodyguard-Lies-Extraordinary-Story-Behind/dp/1585746924/ref=pd_sbs_14_2?_encoding=UTF8&pd_rd_i=1585746924&pd_rd_r=3SK5GZ3H7N4S1X1YCWKP&pd_rd_w=HhVAP&pd_rd_wg=odX6H&psc=1&refRID=3SK5GZ3H7N4S1X1YCWKP)
</Markdown>
<Markdown>
					- [Reveille for Radicals](https://www.amazon.com/Reveille-Radicals-Saul-Alinsky/dp/0679721126/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Cutting Through Spiritual Materialis](https://www.amazon.com/Cutting-Through-Spiritual-Materialism-Chogyam/dp/1570629579)
</Markdown>
<Markdown>
					- [Meditation in Action](https://www.amazon.com/Meditation-Action-Chogyam-Trungpa/dp/159030876X)
</Markdown>
<Markdown>
					- [Cosmic Consciousness](https://www.amazon.com/Cosmic-Consciousness-Study-Evolution-Human/dp/1578989620)
</Markdown>
<Markdown>
					- [Autobiography of a Yogi](https://www.amazon.com/Autobiography-Yogi-P-Yogananda/dp/8120725247)
</Markdown>
<Markdown>
					- [King Lear](https://www.amazon.com/King-Lear-Dover-Thrift-Editions/dp/0486280586)
</Markdown>
<Markdown>
					- [My Journey: Transforming Dreams Into Actions](https://www.amazon.com/My-Journey-Transforming-Dreams-Actions/dp/8129124912/ref=pd_lpo_sbs_14_t_0?_encoding=UTF8&psc=1&refRID=T4V4G5RRRAYM1FEFFK0D)
</Markdown>
<Markdown>
					- [Wings of Fire: An Autobiography of APJ Abdul Kalam](https://www.amazon.com/Wings-Fire-Autobiography-Abdul-Kalam/dp/8173711461)
</Markdown>
<Markdown>
					- [The Spider Network](https://www.amazon.com/Spider-Network-Scheming-Bankers-Greatest/dp/0062452991/ref=sr_1_1?s=books&ie=UTF8&qid=1530827811&sr=1-1&keywords=spider+network)
</Markdown>
<Markdown>
					- [A Guide to the Good Life](https://www.amazon.com/Guide-Good-Life-Ancient-Stoic/dp/1522632735)
</Markdown>
<Markdown>
					- [All Out War: The Full Story of How Brexit Sank Britain's Political Class](https://www.amazon.com/All-Out-War-Britains-Political/dp/0008215154)
</Markdown>
<Markdown>
					- [When the Wolves Bite](https://www.amazon.com/When-Wolves-Bite-Billionaires-Company/dp/1610398270/ref=sr_1_1?s=books&ie=UTF8&qid=1530828860&sr=1-1&keywords=when+the+wolves+bite)
</Markdown>
<Markdown>
					- [But What If We're Wrong?](https://www.amazon.com/But-What-If-Were-Wrong/dp/0399184139/ref=sr_1_1?s=books&ie=UTF8&qid=1530829390&sr=1-1&keywords=but+what+if+we%27re+wrong)
</Markdown>
<Markdown>
					- [Thinking in Bets: Making Smarter Decisions When You Don't Have All the Facts](https://www.amazon.com/Thinking-Bets-Making-Smarter-Decisions/dp/0735216355/ref=sr_1_1?s=books&ie=UTF8&qid=1530827598&sr=1-1&keywords=thinking+in+bets)
</Markdown>
<Markdown>
					- [Skin in the Game](https://www.amazon.com/Skin-Game-Hidden-Asymmetries-Daily/dp/042528462X/ref=pd_sim_14_3?_encoding=UTF8&pd_rd_i=042528462X&pd_rd_r=9e9dbcf1-817e-11e8-8055-c563715e95aa&pd_rd_w=6flqH&pd_rd_wg=noWZM&pf_rd_i=desktop-dp-sims&pf_rd_m=ATVPDKIKX0DER&pf_rd_p=7967298517161621930&pf_rd_r=7BTVJ7K10APN4CDK6BM5&pf_rd_s=desktop-dp-sims&pf_rd_t=40701&psc=1&refRID=7BTVJ7K10APN4CDK6BM5)
</Markdown>
<Markdown>
					- [Superforecasting](https://www.amazon.com/Superforecasting-Science-Prediction-Philip-Tetlock/dp/0804136696/ref=pd_sim_14_2?_encoding=UTF8&pd_rd_i=0804136696&pd_rd_r=9e9dbcf1-817e-11e8-8055-c563715e95aa&pd_rd_w=6flqH&pd_rd_wg=noWZM&pf_rd_i=desktop-dp-sims&pf_rd_m=ATVPDKIKX0DER&pf_rd_p=7967298517161621930&pf_rd_r=7BTVJ7K10APN4CDK6BM5&pf_rd_s=desktop-dp-sims&pf_rd_t=40701&psc=1&refRID=7BTVJ7K10APN4CDK6BM5)
</Markdown>
<Markdown>
					- [The Signal and the Noise](https://www.amazon.com/Signal-Noise-Many-Predictions-Fail-but/dp/0143125087/ref=pd_sim_14_6?_encoding=UTF8&pd_rd_i=0143125087&pd_rd_r=9e9dbcf1-817e-11e8-8055-c563715e95aa&pd_rd_w=6flqH&pd_rd_wg=noWZM&pf_rd_i=desktop-dp-sims&pf_rd_m=ATVPDKIKX0DER&pf_rd_p=7967298517161621930&pf_rd_r=7BTVJ7K10APN4CDK6BM5&pf_rd_s=desktop-dp-sims&pf_rd_t=40701&psc=1&refRID=7BTVJ7K10APN4CDK6BM5)
</Markdown>
<Markdown>
					- [When Genius Failed: The Rise and Fall of Long-Term Capital Management ](https://www.amazon.com/When-Genius-Failed-Long-Term-Management/dp/0375758259/ref=pd_rhf_se_s_cp_0_4?_encoding=UTF8&pd_rd_i=0375758259&pd_rd_r=1GQM7BCVKNDXWSJRTTDA&pd_rd_w=pB4Tq&pd_rd_wg=YGeTh&psc=1&refRID=1GQM7BCVKNDXWSJRTTDA)
</Markdown>
<Markdown>
					- [Rules for Radicals: A Practical Primer for Realistic Radicals](https://www.amazon.com/Rules-Radicals-Practical-Primer-Realistic/dp/0679721134/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [The Moral Sayings of Publius Syrus](https://www.amazon.com/Moral-Sayings-Publius-Syrus-Roman/dp/1614276668/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [A Sense of Urgency](https://www.amazon.com/Sense-Urgency-John-P-Kotter/dp/1501246976)
</Markdown>
<Markdown>
					- [Leading Change](https://www.amazon.com/Leading-Change-New-Preface-Author/dp/1422186431)
</Markdown>
<Markdown>
					- [The Apprenticeship of Duddy Kravitz](https://www.amazon.com/Apprenticeship-Duddy-Kravitz-Mordecai-Richler/dp/0671028472/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Frontinus: The Strategemata](http://penelope.uchicago.edu/Thayer/E/roman/Texts/Frontinus/Strategemata/home.html)
</Markdown>
<Markdown>
					- [The Measure of My Days: One Woman's Vivid, Enduring Celebration of Life and Aging](https://www.amazon.com/Measure-My-Days-Enduring-Celebration/dp/0140051643/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Twelve against the gods: The story of adventure](https://www.amazon.com/Twelve-against-gods-story-adventure/dp/B0007DSQ56/?tag=ryanholnet-20)
</Markdown>
<Markdown>
					- [Leonardo da Vinci](https://www.amazon.com/Leonardo-Vinci-Walter-Isaacson/dp/1501139150/ref=pd_sim_14_2?_encoding=UTF8&pd_rd_i=1501139150&pd_rd_r=6c7b8b55-7d0e-11e8-be1e-91ec4411289b&pd_rd_w=fi6P0&pd_rd_wg=E6Eox&pf_rd_i=desktop-dp-sims&pf_rd_m=ATVPDKIKX0DER&pf_rd_p=7967298517161621930&pf_rd_r=463YEZ6VYHNJGV42A8W5&pf_rd_s=desktop-dp-sims&pf_rd_t=40701&psc=1&refRID=463YEZ6VYHNJGV42A8W5)
</Markdown>
<Markdown>

					- [Shauns RL](http://investorfieldguide.com/wp-content/uploads/2016/07/Full-Reading-List.pdf)
</Markdown>
<Markdown>
					- [Letters From A Self-Made Merchant To His Son](https://www.amazon.com/Letters-Self-Made-Merchant-His-Son/dp/1502586266/?tag=ryanholnet-20)
</Markdown>
			</section>
		</Layout>
	)
}
// render(
   
//     document.body
// );

